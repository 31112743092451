import React from "react";
import { SectionContainer } from "./styles";
import classNames from "classnames";

const Section = ({ children, pd, bg, className }) => {
  return (
    <SectionContainer pd={pd} bg={bg}  className={classNames(className)}>
      {children}
    </SectionContainer>
  );
};

export default Section;
