import React from "react";
import {
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import SidebarMenu from "../SidebarMenu/SidebarMenu";
import SidebarCart from "../SidebarCart/SidebarCart";
// import account from "../../assets/images/account.svg";
// import shoppingcart from "../../assets/images/cart.svg";
import logo from "../../assets/images/logo.png";
import {
  Badge,
  Icon,
  MenuIcon,
  NavbarContainer,
  NavbarList,
  NavbarUpper,
  NavbarSection,
  NavbarLogo,
  NavbarIconList,
  NavbarIconLink,
  NavbarMobileLogo,
  NavbarIconMobileList,
  NavLinking,
  SearchContainerDesktop,
  ShapeCircle,
  NavbarLower,
  NavbarLowerList,
  NavbarLink,
  NavbarLink2
} from "./styles";
import {
  Box,
  NavbarSidebarList,
  SearchField,
  SearchButton,
  SearchIcon,
} from "./styles";
import { get_user_profile } from "../../api/API";
import useCart from "../../hooks/useCart";

const useStyle = makeStyles((theme) => ({
  sidenavbar: {
    width: 300,
  },
}));


const Navbar = () => {
  const history = useHistory();
  const classes = useStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [openSideMenu, setOpenSideMenu] = React.useState({ left: false });

  const [openSideCart, setOpenSideCart] = React.useState({ right: false });

  const handleDrawerToggle = (anchor, open) => (e) => {
    if (e.type == "keydown" && (e.key == "Tab" || e.key == "Shift")) {
      return;
    }
    setOpenSideMenu({ [anchor]: open });
    setOpenSideCart({ [anchor]: open });
  };

  //

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // HANDLE LOGOUT

  const handleLogout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("user_id");
    window.localStorage.removeItem("length");
    // window.localStorage.removeItem("cart");
    history.push("/");
    window.location.reload();
  };

  //

  // CART COUNT

  let user_id = window.localStorage.getItem("user_id");

  const { getCart } = useCart();

  const [cart, setCart] = React.useState(getCart);

  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);


  const handleChangePage = ()=> {
    history.push("/contact");
    window.locaction.replace("/#contact")
  } 

  return (
    <NavbarContainer>
      <Container maxWidth="lg">
        <AppBar position="static">
          <Toolbar>
            {isMobile ? (
              <>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerToggle("left", true)}
                >
                  <MenuIcon color="#5b5692" />
                </IconButton>
                <NavbarMobileLogo>
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                </NavbarMobileLogo>
                <NavbarIconMobileList>
                  <Link className="theIcon" onClick={handleMenu}>
                    <span className="theCircle">
                      {/* <img src={account} /> */}
                    </span>
                  </Link>
                  {/* {token ? (
                    <NavbarIconLink onClick={handleMenu}>
                      <ShapeCircle>
                        <Icon src={account} />
                      </ShapeCircle>
                    </NavbarIconLink>
                  ) : (
                    <NavbarIconLink to="/login">
                      <ShapeCircle>
                        <Icon src={account} />
                      </ShapeCircle>
                    </NavbarIconLink>
                  )} */}
                  {/* <NavbarIconLink onClick={handleMenu}>
                    <ShapeCircle>
                      <Icon src={account} />
                    </ShapeCircle>
                  </NavbarIconLink>

                  <NavbarIconLink onClick={handleDrawerToggle("right", true)}>
                    <ShapeCircle margin={"0 0 0 1rem"}>
                      <Icon src={shoppingcart} />
                    </ShapeCircle>
                    {cart != undefined ? (
                      <Badge>{cart ? cart.length : null}</Badge>
                    ) : (
                      <></>
                    )}
                  </NavbarIconLink> */}
                </NavbarIconMobileList>

                <SidebarMenu
                  anchor="left"
                  open={openSideMenu.left}
                  onOpen={handleDrawerToggle("left", true)}
                  onClose={handleDrawerToggle("left", false)}
                  handleDrawerToggle={handleDrawerToggle("left", false)}
                />
              </>
            ) : (
              <>
                <NavbarList>
                  <NavbarUpper>
                    <NavbarLogo to="/">
                      <img src={logo} />
                    </NavbarLogo>
                    <NavbarSection>
                      <NavbarLink color="#1e1e1e" to="/">
                        Home
                      </NavbarLink>
                      <NavbarLink color="#1e1e1e" to="/about">
                        About
                      </NavbarLink>
                      <NavbarLink color="#1e1e1e" to="/product">
                        Product
                      </NavbarLink>
                      <NavbarLink2 color="#1e1e1e" to="/#contact">
                        Contact
                      </NavbarLink2>
                    </NavbarSection>
                  </NavbarUpper>

                </NavbarList>
              </>
            )}
            <>
              <Menu
                id="menu-appbar"
                elevation={0}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                anchorPosition={{
                  top: 300,
                }}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                <MenuItem>
                  <NavLinking>Welcome, xxx</NavLinking>
                </MenuItem>
                <MenuItem>
                  <NavLinking to="/account">Account</NavLinking>
                </MenuItem>

                <MenuItem>
                  <NavLinking to="/orders">Orders</NavLinking>
                </MenuItem>
                <MenuItem>
                  <NavLinking onClick={() => handleLogout()}>Logout</NavLinking>
                </MenuItem>
              </Menu>
            </>

            <SidebarCart
              anchor="right"
              open={openSideCart.right}
              onOpen={handleDrawerToggle("right", true)}
              onClose={handleDrawerToggle("right", false)}
              handleDrawerToggle={handleDrawerToggle("right", false)}
            />
          </Toolbar>
        </AppBar>
      </Container>
    </NavbarContainer>
  );
};

export default Navbar;
