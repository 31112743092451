import styled from "styled-components";

export const SectionContainer = styled.div`
  position: relative;

  @media screen and (min-width: 360px) {
    padding: ${(props) => (props.pd ? props.pd : "1rem")};
    background: ${(props) => (props.bg ? props.bg : "#fff")};
  }
`;
