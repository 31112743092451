import React, { useContext } from "react";
import { CartContext } from "../context/CartContext";

const Storage = (cartItems) => {
  localStorage.setItem(
    "cart",
    JSON.stringify(cartItems.length > 0 ? cartItems : [])
  );
};

const useCart = () => {
  const [cart, setCart] = useContext(CartContext);

  function getCart() {
    return cart;
  }

  function clearCart() {
    setCart([]);
    Storage([]);
  }

  function setCartFunction(cart = []) {
    // alert(JSON.stringify(cart))
    setCart(cart);
  }
  function addProduct(item) {
    var cartTemp = [...cart];
    var newItem = { ...item };

    if (cartTemp.length === 0) {
      newItem["index"] = 0;
      cartTemp.push(newItem);
    } else {
      var existed = 0;
      var index = 0;
      for (var i = 0; i < cartTemp.length; i++) {
        if (
          cartTemp[i].product_id == newItem.product_id) {
          existed = 1;
          index = i;
        }
      }
      if (existed == 1) {
        var new_quantity =
          parseFloat(cartTemp[index].quantity) + parseFloat(newItem.quantity);
        cartTemp[index]["quantity"] = new_quantity;
        cartTemp[index]["price"] =
          parseFloat(cartTemp[index].single_price) * parseFloat(new_quantity);
      } else {
        newItem["index"] = cartTemp.length;
        cartTemp.push(newItem);
      }
    }
    setCart(cartTemp);
    Storage(cartTemp);
    window.localStorage.setItem("length", cartTemp.length);
    console.log(cartTemp);
  }

  function increaseProduct(index) {
    var cartTemp = [...cart];
    var quantity = parseFloat(cartTemp[index].quantity) + 1;
    cartTemp[index]["quantity"] = quantity;
    cartTemp[index]["price"] =
      parseFloat(cartTemp[index].single_price) * parseFloat(quantity);
    setCart(cartTemp);
    Storage(cartTemp);
    console.log(cartTemp);
  }

  function decreaseProduct(index) {
    var cartTemp = [...cart];
    var quantity = parseFloat(cartTemp[index].quantity) - 1;

    if (quantity > 0) {
      cartTemp[index]["quantity"] = quantity;
      cartTemp[index]["price"] =
        parseFloat(cartTemp[index].single_price) * parseFloat(quantity);
    } else {
      cartTemp.splice(index, 1);
      // window.localStorage.setItem('length', cartTemp.length - 1)
      for (var i = 0; i < cartTemp.length; i++) {
        cartTemp[i]["index"] = i;
      }
    }

    setCart(cartTemp);
    Storage(cartTemp);
    console.log(cartTemp);
  }

  function getTotalCart() {
    var cartTemp = [...cart];
    var total_cart_price = 0;

    for (var i = 0; i < cartTemp.length; i++) {
      total_cart_price +=
        parseFloat(cartTemp[i].single_price) * parseFloat(cartTemp[i].quantity);
    }
    return total_cart_price;
  }

  function removeProduct(index) {
    var cartTemp = [...cart];
    cartTemp.splice(index, 1);

    for (var i = 0; i < cartTemp.length; i++) {
      cartTemp[i]["index"] = i;
    }

    setCart(cartTemp);
    Storage(cartTemp);
  }

  return {
    addProduct,
    clearCart,
    getTotalCart,
    removeProduct,
    getCart,
    increaseProduct,
    decreaseProduct,
    setCartFunction,
  };
};

export default useCart;
