import React from "react";
import classNames from "classnames";
import useStyles, { CardContainer } from "./styles";
import { Text } from "./styles";

const Card = ({ className, children, ...others }) => {
  const classes = useStyles({});

  return (
    <CardContainer {...others} className={classNames(classes.root, className)}>
      {children}
    </CardContainer>
  );
};

export default Card;
