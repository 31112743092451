import React from "react";
import { LoaderContainer, LoaderStyle } from "./styles";

const Loader = ({
  children,
  ...props
}) => {
  return (
    <LoaderContainer>
      <LoaderStyle {...props}>

      </LoaderStyle>
    </LoaderContainer>
  );
};

export default Loader;
