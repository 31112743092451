import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { md } from "../../styles/responsive";


const config = {
  root: {
    position: "relative",

    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        // height: "100%",
      },
      "& > div > div": {
        // height: "100%",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: -38,

        ...md("sm", {
          right: "-4px",
        }),

        "&:before": {
          content: "",
          content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#000",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: -38,

        ...md("sm", {
          left: "-8px",
        }),

        "&:before": {
          content: "",
          content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#000",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "static",
      marginTop: -40,

      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 8,
          borderRadius: "100px",
          backgroundColor: "#fff",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 30,
          },
        },
      },
    },
  },

  theSection: {
    position: "relative",
    margin: "2rem 0"
  },

  theIMG: {
    position: "relative",
    width: "20%",
    margin: "0 auto",

    "& img": {
      width: "100%"
    }
  },

  theSectionHeight: {
    height: "500px",
    ...md("sm", {
      height: "380px",
    }),
    ...md("xmd", {
      height: "430px",
    }),

  },

  theWrap: {
    position: "relative",
    height: "240px",
    marginBottom: "1rem",
  },

  theBox001: {
    position: "relative",
    width: "80%",
    // margin: "0 auto",
    "& img": {
      width: "100%"
    }
  },

  theBox002: {
    position: "absolute",
    bottom: "-74px",
    right: "-4rem",
    width: "74%",
    margin: "0 auto",

    ...md("md", {
      bottom: "-98px",
      right: "-3rem",
      width: "65%",
    }),

    "& img": {
      width: "100%"
    }
  },

  theLink: {
    color: "#5b5692",
    fontSize: "1.3rem",
    fontWeight: "700",
    textDecoration: "underline"
  },

  theButton: {
    position: "absolute",
    zIndex: 1,
    left: 0,
    bottom: "-60px",

    ...md("xmd", {
      bottom: "-78px",
    }),
  },

  theBtn: {
    padding: "1rem",
    background: "#5b5692",
    color: "#fff",
    display: "flex",
    ...md("xmd", {
      padding: "1.4rem 3rem",
    }),
  },

  theSectionProduct: {
    alignItems: "center",
    display: "flex",
  },

  theProduct: {
    position: "relative",
    width: "100%",
    display: "flex",
    zIndex: 1,
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.5)",
    top: 0,
    left: 0,
    "& img": {
      width: "100%",
    },
  },

  theOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.5)",
    top: 0,
    left: 0,
  },

  theInnerText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },

  theIcon: {
    position: "relative",
    width: "40%",
    margin: "0 auto",

  },

  theIconIMG: {
    position: "relative",
    width: "100%",
    margin: "0 auto",
    "& img": {
      width: "100%"
    }
  },

  theRectangle: {
    position: "relative",
    width: "70%",
    display: "grid",
    "& img": {
      width: "100%"
    }
  },

  theRectangleRight: {
    position: "relative",
    width: "60%",
    display: "flex",
    marginLeft: "auto",

    "& img": {
      width: "100%",
      height: "44.13px"
    }
  },

  theContactIMG: {
    position: "relative",
    width: "100%",
    display: "grid",

    ...md("sm", {
      marginTop: "4rem",
    }),

    ...md("md", {
      width: "80%",
    }),

    "& img": {
      width: "100%"
    }
  },

  theContactForm: {
    position: "relative",
    width: "100%",
    margin: "0 auto",

    ...md("xmd", {
      width: "80%",
    }),
  },
};

export default makeStyles(config);
