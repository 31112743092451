import React, { useState, useCallback } from "react";
import { Container, Grid, Modal, Select, MenuItem } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { Button } from "../../components/Button/Button";
import Carousel from "../../components/Carousel/Carousel";
import { CarouselIMG } from "../../components/Carousel/styles";
import Card from "../../components/Card/Card";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import FormInput from "../../components/FormInput/FormInput";
import Loader from "../../components/Loader/Loader";
import { render } from "react-dom";
import ImageViewer from "react-simple-image-viewer";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Footer from "../../components/Footer/Footer";
import prod001 from "../../assets/images/prod001.JPG";
import Profile_04 from "../../assets/images/Profile_04.JPG";
import product1 from "../../assets/images/product1.jpg";
import product2 from "../../assets/images/product2.JPG";
import product3 from "../../assets/images/product3.JPG";
import product4 from "../../assets/images/product4.JPG";
import product5 from "../../assets/images/product5.JPG";
import product6 from "../../assets/images/product6.jpg";
import product7 from "../../assets/images/product7.JPG";
import product8 from "../../assets/images/product8.jpg";
import service001 from "../../assets/images/service001.jpg";
import service002 from "../../assets/images/service002.jpg";
import service003 from "../../assets/images/service003.jpg";
import Product_11 from "../../assets/images/Product_11.JPG";
import IMG_5245 from "../../assets/images/IMG_5245.JPG";
import IMG_5256 from "../../assets/images/IMG_5256.JPG";
import IMG_5262 from "../../assets/images/IMG_5262.JPG";
import bubbleroll from "../../assets/images/bubbleroll.jpg";
import stickertape from "../../assets/images/stickertape.jpg";
import ppcorrugated from "../../assets/images/ppcorrugated.jpg";
import useStyles, {
  ModalEnquiry,
  ModalHeader,
  ModalBody,
  FlexIcon,
  FormInputSelect,
} from "./styles";
import {} from "../../api/API";
import { ModeCommentOutlined } from "@material-ui/icons";


export default function Product() {
  const classes = useStyles();

  let { Product } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Product]);

  // MODAL

  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // API

  const [isBusy, setIsBusy] = React.useState(true);

  React.useEffect(() => {
    setIsBusy(true);
  }, []);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    product1,product2,product3,product4
  ];


  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const [livePicture, setLivePicture] = useState(0);
  const [viewerOpen, setViewerOpen] = useState(false);
  const picture = [
    product5,product6,product7,product8
  ];

  const onImageViewer = useCallback((model) => {
    setLivePicture(model);
    setViewerOpen(true);
  }, []);

  const  offImageViewer = () => {
    setLivePicture(0);
    setViewerOpen(false);
  };

  return (
    <React.Fragment>
      <Navbar />
      {isBusy != true ? (
        <>
          <Loader
            type="TailSpin"
            color="#ff8001"
            height={80}
            timeout={6000}
            width={80}
          />
        </>
      ) : (
        <>
          <Container>
            <Section pd="2rem 1rem">
              <div className={classes.theSection}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className={classes.theIMG}>
                      <img src={prod001} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className={classes.theProdDetail}>
                      <Paragraph size="600" bold="700" margin="1rem 0">
                        Polyethylene Foam
                      </Paragraph>
                      <Paragraph size="500" margin="1rem 0">
                        Polyethylene foam is durable, lightweight, and
                        resilient, the perfect material for protecting products
                        that are high in value and relatively fragile. he
                        benefit of PE foam is that it is highly resistant to
                        damage and moisture, which makes it an excellent choice
                        for items that need special care. It also dampens
                        vibrations and acts as an insulator.{" "}
                      </Paragraph>
                      <div className={classes.theBtn}>
                        <Button
                          label="Leave An Inquiry"
                          bg="#5b5692"
                          color="#fff"
                          pd="1rem"
                          onClick={() => handleOpenModal()}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Section>
          </Container>
          <Section pd="0">
            <Container>
              <Paragraph size="600" bold="700" margin="1rem 0">
                Gallery
              </Paragraph>
            </Container>
            <div className={classes.theSectionProduct}>
              {/* <SRLWrapper customCaptions={customCaptions}>
                <p>123</p>
                <Grid container spacing={0}>
                  product1
                  <Grid item xs={6} sm={6} md={3}>
                    <a href={product2} className={classes.theProduct}>
                      <img src={product2} />
                    </a>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <a href={product3} className={classes.theProduct}>
                      <img src={product3} />
                    </a>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <a href={product4} className={classes.theProduct}>
                      <img src={product4} />
                    </a>
                  </Grid>
                </Grid>
              </SRLWrapper> */}
							<Grid container spacing={0}>
              {images.map((src, index) => (
								<>
								<Grid item xs={6} sm={6} md={3}>
                <img
                  src={src}
                  onClick={() => openImageViewer(index)}
                  width="100%"
                  key={index}
                  style={{ margin: "2px" }}
                  alt=""
                />
								</Grid>
								</>
              ))}

              {isViewerOpen && (
                <ImageViewer
                  src={images}
                  currentIndex={currentImage}
                  disableScroll={false}
                  closeOnClickOutside={true}
                  onClose={closeImageViewer}
                />
              )}
							</Grid>
            </div>
          </Section>

          <Container>
            <Section pd="2rem 1rem">
              <div className={classes.theSection}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className={classes.theProdDetail}>
                      <Paragraph size="600" bold="700" margin="1rem 0">
                        Polyurethane Foam
                      </Paragraph>
                      <Paragraph size="500" margin="1rem 0">
                        Polyurethane is one of the most versatile types of foam
                        due to its ability to be manipulated into many different
                        forms. This is an open cell, soft foam that is easy to
                        mold and fit different products. Because of its soft
                        nature, it’s able to absorb shock and recover its
                        original shape quickly. PU is an excellent option to
                        protect light-weight fragile products because of its
                        softness and ability to resist scratches and dents. It
                        also has a low odor and a resistance to mildew, which
                        makes it perfect for shipping foods or
                        moisture-sensitive products.{" "}
                      </Paragraph>
                      <div className={classes.theBtn}>
                        <Button
                          label="Leave An Inquiry"
                          bg="#5b5692"
                          color="#fff"
                          pd="1rem"
                          onClick={() => handleOpenModal()}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className={classes.theIMG}>
                      <img src={Profile_04} />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Section>
          </Container>
          <Section pd="0">
            <Container>
              <Paragraph size="600" bold="700" margin="1rem 0">
                Gallery
              </Paragraph>
            </Container>
            <div className={classes.theSectionProduct}>
              {/* <SRLWrapper>
                <p>456</p>
                <Grid container spacing={0}>
                  <Grid item xs={6} sm={6} md={3}>
                    <a href={product5} className={classes.theProduct}>
                      <img src={product5} />
                    </a>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <a href={product6} className={classes.theProduct}>
                      <img src={product6} />
                    </a>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <a href={product7} className={classes.theProduct}>
                      <img src={product7} />
                    </a>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <a href={product008} className={classes.theProduct}>
                      <img src={product008} />
                    </a>
                  </Grid>
                </Grid>
              </SRLWrapper> */}


							<Grid container spacing={0}>
              {picture.map((src, model) => (
								<>
								<Grid item xs={6} sm={6} md={3}>
                <img
                  src={src}
                  onClick={() => onImageViewer(model)}
                  width="100%"
                  key={ModeCommentOutlined}
                  style={{ margin: "2px" }}
                  alt=""
                />
								</Grid>
								</>
              ))}

              {viewerOpen && (
                <ImageViewer
                  src={picture}
                  currentModel={livePicture}
                  disableScroll={false}
                  closeOnClickOutside={true}
                  onClose={offImageViewer}
                />
              )}
							</Grid>
            </div>
          </Section>

          <Container>
            <Section pd="2rem 1rem">
              <Paragraph size="600" bold="700" margin="0">
                Other Products
              </Paragraph>
              <div className={classes.theSection}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} md={4}>
                    <div className={classes.theService}>
                      <img src={service003} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={8} md={7}>
                    <div className={classes.theProdDetail}>
                      <Paragraph size="600" bold="700" margin="1rem 0">
                        Precision Pallets
                      </Paragraph>
                      {/* <Paragraph size="500" margin="1rem 0">
                        Pallets allow for efficiencies in storage and handling
                        by providing the base for assembling, handling, and
                        transporting materials and products.{" "}
                      </Paragraph>
                      <div className={classes.theBtn}>
                        <Button
                          label="Leave An Inquiry"
                          bg="#5b5692"
                          color="#fff"
                          pd="1rem"
                          onClick={() => handleOpenModal()}
                        />
                      </div> */}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.theSection}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} md={4}>
                    <div className={classes.theService}>
                      <img src={Product_11} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={8} md={7}>
                    <div className={classes.theProdDetail}>
                      <Paragraph size="600" bold="700" margin="1rem 0">
                        Expanded Polystyrene (EPS)
                      </Paragraph>
                      {/* <Paragraph size="500" margin="1rem 0">
                      Expanded Polystyrene insulation is a lightweight, rigid, closed cell insulation. 
                      EPS is available in several compressive strengths to withstand load and back-fill 
                      forces.{" "}
                      </Paragraph>
                      <div className={classes.theBtn}>
                        <Button
                          label="Leave An Inquiry"
                          bg="#5b5692"
                          color="#fff"
                          pd="1rem"
                          onClick={() => handleOpenModal()}
                        />
                      </div> */}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.theSection}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} md={4}>
                    <div className={classes.theService}>
                      <img src={IMG_5245} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={8} md={7}>
                    <div className={classes.theProdDetail}>
                      <Paragraph size="600" bold="700" margin="1rem 0">
                        EVA Foam
                      </Paragraph>
                      {/* <Paragraph size="500" margin="1rem 0">
                      EVA foam is easy to work with and comes in a variety of colors and thicknesses. 
                      With such variety, EVA foam flooring is perfect for exercise rooms, gyms, weight rooms, 
                      trade shows, martial arts studios and more.{" "}
                      </Paragraph>
                      <div className={classes.theBtn}>
                        <Button
                          label="Leave An Inquiry"
                          bg="#5b5692"
                          color="#fff"
                          pd="1rem"
                          onClick={() => handleOpenModal()}
                        />
                      </div> */}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.theSection}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} md={4}>
                    <div className={classes.theService}>
                      <img src={IMG_5256} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={8} md={7}>
                    <div className={classes.theProdDetail}>
                      <Paragraph size="600" bold="700" margin="1rem 0">
                        Carton
                      </Paragraph>
                      {/* <Paragraph size="500" margin="1rem 0">
                      Carton packaging is an incredibly popular choice for product packaging. Everything from cereal to 
                      cosmetics comes in carton packaging due to the level of customization for a wide range of box sizes.{" "}
                      </Paragraph>
                      <div className={classes.theBtn}>
                        <Button
                          label="Leave An Inquiry"
                          bg="#5b5692"
                          color="#fff"
                          pd="1rem"
                          onClick={() => handleOpenModal()}
                        />
                      </div> */}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.theSection}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} md={4}>
                    <div className={classes.theService}>
                      <img src={IMG_5262} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={8} md={7}>
                    <div className={classes.theProdDetail}>
                      <Paragraph size="600" bold="700" margin="1rem 0">
                        Paper Pulp
                      </Paragraph>
                      {/* <Paragraph size="500" margin="1rem 0">
                      Paper pulp, raw material for paper manufacture that contains vegetable, 
                      mineral, or man-made fibres. It forms a matted or felted sheet on a screen 
                      when moisture is removed.{" "}
                      </Paragraph>
                      <div className={classes.theBtn}>
                        <Button
                          label="Leave An Inquiry"
                          bg="#5b5692"
                          color="#fff"
                          pd="1rem"
                          onClick={() => handleOpenModal()}
                        />
                      </div> */}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.theSection}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} md={4}>
                    <div className={classes.theService}>
                      <img src={bubbleroll} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={8} md={7}>
                    <div className={classes.theProdDetail}>
                      <Paragraph size="600" bold="700" margin="1rem 0">
                        Bubble Roll / Bag
                      </Paragraph>
                      {/* <Paragraph size="500" margin="1rem 0">
                      Bubble wrap is a pliable transparent plastic material used for packing fragile items. 
                      Regularly spaced, protruding air-filled hemispheres provide cushioning for fragile items.{" "}
                      </Paragraph>
                      <div className={classes.theBtn}>
                        <Button
                          label="Leave An Inquiry"
                          bg="#5b5692"
                          color="#fff"
                          pd="1rem"
                          onClick={() => handleOpenModal()}
                        />
                      </div> */}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.theSection}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} md={4}>
                    <div className={classes.theService}>
                      <img src={stickertape} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={8} md={7}>
                    <div className={classes.theProdDetail}>
                      <Paragraph size="600" bold="700" margin="1rem 0">
                       Sticker / Tape
                      </Paragraph>
                      {/* <Paragraph size="500" margin="1rem 0">
                      Tape refers to any one of a variety of combinations of backing materials coated with an adhesive. 
                      Different backing materials and adhesives can be used depending on the intended use.{" "}
                      </Paragraph>
                      <div className={classes.theBtn}>
                        <Button
                          label="Leave An Inquiry"
                          bg="#5b5692"
                          color="#fff"
                          pd="1rem"
                          onClick={() => handleOpenModal()}
                        />
                      </div> */}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.theSection}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} md={4}>
                    <div className={classes.theService}>
                      <img src={ppcorrugated} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={8} md={7}>
                    <div className={classes.theProdDetail}>
                      <Paragraph size="600" bold="700" margin="1rem 0">
                        PP Corrugated
                      </Paragraph>
                      {/* <Paragraph size="500" margin="1rem 0">
                      P Corrugated Board is a plastic medium with character of hygienic, durable, versatile, lightweight, 
                      tough, flexible, easy to form, water resistant and able to resist to chemical, and is 100% recyclable. 
                      Its end product is definitely a better idea over conventional packaging & protection materials.{" "}
                      </Paragraph>
                      <div className={classes.theBtn}>
                        <Button
                          label="Leave An Inquiry"
                          bg="#5b5692"
                          color="#fff"
                          pd="1rem"
                          onClick={() => handleOpenModal()}
                        />
                      </div> */}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Section>
          </Container>
          <Footer />
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <ModalEnquiry>
              {/* <ModalHeader>
								<FlexIcon onClick={() => handleCloseModal()}>
									<Close className={classes.theActionLink} />
								</FlexIcon>
							</ModalHeader> */}
              <ModalBody>
                <Paragraph size="600" bold="700" margin="1rem 0">
                  Enquiry Form
                </Paragraph>
                <br />
                <FormInput
                  label="Your Name"
                  bold="500"
                  bg="#eaeaea"
                  color="#1e1e1e"
                  placeholder="Your Name"
                  name="name"
                />
                <FormInput
                  label="Your Company"
                  bold="500"
                  bg="#eaeaea"
                  color="#1e1e1e"
                  placeholder="Your Company"
                  name="company"
                />
                <FormInput
                  label="Your Email"
                  bold="500"
                  bg="#eaeaea"
                  color="#1e1e1e"
                  placeholder="Your Email"
                  name="email"
                />
                <Paragraph
                  size="300"
                  color="#495057"
                  bold="500"
                  margin="1rem 0"
                >
                  Product Intersted
                </Paragraph>
                <FormInputSelect>
                  <Select
                    // onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    name="product_id"
                    // value={displayDeliveryTime.time}
                    disabled
                  >
                    <MenuItem disabled>Select Product</MenuItem>
                  </Select>
                </FormInputSelect>
                <FormInput
                  label="Message"
                  bold="500"
                  bg="#eaeaea"
                  color="#1e1e1e"
                  placeholder="Message"
                  rows="4"
                  multiline
                />
                <br />
                <div className={classes.theSubmitBtn}>
                  <Button
                    label="Submit"
                    bg="#5b5692"
                    color="#fff"
                    pd="1rem 3rem"
                  />
                </div>
              </ModalBody>
            </ModalEnquiry>
          </Modal>
        </>
      )}
    </React.Fragment>
  );
}
