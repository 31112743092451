import React from "react";
import { Route } from "react-router-dom";
import HomePage from "./pages/Home/Home";
import ProductPage from "./pages/Product/Product";
import AboutPage from "./pages/About/About";
import ContactPage from "./pages/Contact/Contact";

export default function Routes() {
  return (
    <div>
      <Route
        exact
        path="/"
        render={(props) => <HomePage {...props}></HomePage>}
      />
      <Route
        exact
        path="/product"
        render={(props) => <ProductPage {...props}></ProductPage>}
      />
      <Route
        exact
        path="/about"
        render={(props) => <AboutPage {...props}></AboutPage>}
      />
      {/* <Route
        exact
        path="/contact"
        render={(props) => <ContactPage {...props}></ContactPage>}
      /> */}
    </div>
  );
}
