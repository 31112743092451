import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { md } from "../../styles/responsive";


const config = {
    root: {
        position: "relative",

    },

    theFlex: {
        position: "relative",
        alignItems: "center",
        display: "flex",
        margin: "0 0 .8rem 0",

    },

    theIcon: {
        color: "#fff",
        margin: "0 1rem 0 0",
    }

};

export default makeStyles(config);
