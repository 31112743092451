import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Close, Search } from "@material-ui/icons";

export const NavbarSidebarList = styled.div`
  padding: 1rem;
`;

export const NavbarSidebarListLogo = styled.div`
  img {
    width: 200px;
  }
`;

export const CloseButtonBox = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const CloseIcon = styled(Close)`
  color: #000;
`;

export const SearchContainer = styled.div`
  display: flex;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 100%;
  margin: 2rem 0;
  align-items: center;
`;

export const Box = styled.div`
  padding: 0.66rem 1rem;
  border-top: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #f8f8f8;
  p {
    color: #000;
    margin: 0;
  }
`;

export const SearchField = styled(TextField)`
  display: block !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #eeeeee;
  background-clip: padding-box;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export const SearchButtonMoblie = styled(Link)`
  /* background: #7f222c; */
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  padding: 0.6rem 0.8rem;
  /* border-top-right-radius: 20px;
  border-bottom-right-radius: 20px; */
  align-items: center;
  display: flex;
`;

export const SearchIcon = styled(Search)`
  color: ${({ color }) => color};
`;
