import React from "react";
import { Container, Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import Navbar from "../../components/Navbar/Navbar";
import { Button } from "../../components/Button/Button";
// import Carousel from "../../components/Carousel/Carousel";
import Card from "../../components/Card/Card";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import FormInput from "../../components/FormInput/FormInput";
import Loader from "../../components/Loader/Loader";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Footer from "../../components/Footer/Footer";
import banner from "../../assets/images/banner.png";
import box from "../../assets/images/box.png";
import config from "../../assets/images/config.png";
import quality from "../../assets/images/quality.png";
import default001 from "../../assets/images/default001.png";
import default002 from "../../assets/images/default002.png";
import purplerectangle from "../../assets/images/purplerectangle.png";
import bluerectangle from "../../assets/images/bluerectangle.png";
import "./home.css";
import bed from "../../assets/images/bed.png";
import beg from "../../assets/images/beg.png";
import electronic from "../../assets/images/electronic.png";
import marine from "../../assets/images/marine.png";
import construction from "../../assets/images/construction.png";
import furniture from "../../assets/images/furniture.png";
import medical from "../../assets/images/medical.png";
import sport from "../../assets/images/sport.png";
import useStyles from "./styles";
import { SRLWrapper } from "simple-react-lightbox";
import dot001 from "../../assets/images/dot001.png";
import dot002 from "../../assets/images/dot002.png";
import product001 from "../../assets/images/product001.jpg";
import product002 from "../../assets/images/product002.jpg";
import product003 from "../../assets/images/product003.jpg";
import product004 from "../../assets/images/product004.jpg";
import wavyicon from "../../assets/images/wavyicon.png";
import Slider from "react-slick";
import "animate.css";
import { } from "../../api/API";
import AOS from "aos";

export default function Home() {
  const classes = useStyles();

  let { Home } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Home]);

  // API

  const [isBusy, setIsBusy] = React.useState(true);

  React.useEffect(() => {
    setIsBusy(true);
    AOS.init({
      duration: 1000,
    });
  }, []);

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // initialSlide: 0,
    dots: false,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 5000,
  };

  return (
    <React.Fragment>
      <Navbar />
      {isBusy != true ? (
        <>
          <Loader
            type="TailSpin"
            color="#ff8001"
            height={80}
            timeout={6000}
            width={80}
          />
        </>
      ) : (
        <>
          {/* <Carousel>
              <CarouselIMG src={banner} />
              <CarouselIMG src={banner} />
            </Carousel> */}
          <div className="slider-home">
            <Slider {...settings}>
              <div>
                <div className="c-img">
                  <img src={banner} />
                  <div data-aos="zoom-in" className="c-aniBorder">
                    <div data-aos="zoom-out" data-aos-delay="300" className="c-animation">
                      <p className="c-sliderText">thing within the box</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="c-img">
                  <img src={banner} />
                  <div data-aos="zoom-in" className="c-aniBorder">
                    <div data-aos="zoom-out" data-aos-delay="300" className="c-animation">
                      <p className="c-sliderText">thing within the box</p>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          <Container>
            <Section pd="2rem 1rem">
              <Paragraph size="600" bold="600" margin=" 0" center>
                Our Experience Will Help
              </Paragraph>
              <Paragraph size="600" bold="600" margin=" 0" center>
                You Create a Quality Product{" "}
              </Paragraph>
              <div className={classes.theSection}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} md={4}>
                    <Card>
                      <div className={classes.theIMG}>
                        <img src={box} />
                      </div>
                      <Paragraph size="500" bold="700" margin="1rem 0" center>
                        Professional Packaging
                      </Paragraph>
                      <Paragraph size="300" margin="1rem 0">
                        Stand out in the market from your competitors and help
                        people recognize your brand with our professionally
                        crafted packaging that fits any industry.
                      </Paragraph>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Card>
                      <div className={classes.theIMG}>
                        <img src={config} />
                      </div>
                      <Paragraph size="500" bold="700" margin="1rem 0" center>
                        Customized Solution
                      </Paragraph>
                      <Paragraph size="300" margin="1rem 0">
                        Looking for a distinct design, specific finish or
                        working within a certain budget? Our highly experienced
                        team is here to put together a custom-made packaging
                        solution for you. Get a quote now!{" "}
                      </Paragraph>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Card>
                      <div className={classes.theIMG}>
                        <img src={quality} />
                      </div>
                      <Paragraph size="500" bold="700" margin="1rem 0" center>
                        Quality Assurance
                      </Paragraph>
                      <Paragraph size="300" margin="1rem 0">
                        We place a high degree of emphasis on making sure that
                        your products are appropriately protected and meet a
                        certain standard in line with the packaging industry
                        guidelines.
                      </Paragraph>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </Section>
          </Container>

          <Section
            pd="2rem 1rem"
            bg="#f2f2f2"
            className={classes.theSectionHeight}
          >
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <div className={classes.theWrap}>
                    <div className={classes.theBox001}>
                      <img src={default001} />
                      <div className={classes.theBox002}>
                        <img src={default002} />
                      </div>
                      <div className={classes.theButton}>
                        <div className={classes.theBtn}>WHERE WE WORK</div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Paragraph size="600" bold="700" margin="1rem 0">
                    Who Are We
                  </Paragraph>
                  <Paragraph size="400" margin="1rem 0">
                    We are a professional driven packaging team that strives to
                    deliver the best service at a reasonable price to our
                    customers. Our team are highly trained to meet your needs
                    and make sure you have the best customer experience. Contact
                    us now to get to know us better!
                  </Paragraph>
                  <Link to="/#contact" className={classes.theLink}>
                    <Paragraph size="400" bold="700" margin="1rem 0">
                      {" "}
                      Learn more about us
                    </Paragraph>
                  </Link>
                </Grid>
              </Grid>
            </Container>
          </Section>

          <Section pd="0">
            <div className={classes.theSectionProduct}>
              <SRLWrapper>
                <Grid container spacing={0}>
                  <Grid item xs={4} sm={4} md={4}>
                    <a className={classes.theProduct}>
                      <img src={product001} alt="Core Product 1" />
                      <div className={classes.theInnerText}>
                        <Paragraph
                        
                          color="#fff"
                          size="500"
                          bold="400"
                          margin="1rem 0"
                          center
                        >
                          Polyethylene Foam
                        </Paragraph>
                      </div>
                    </a>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <a className={classes.theProduct}>
                      <img src={product002} alt="Core Product 2" />
                      {/* <div className={classes.theOverlay}> */}
                      <div className={classes.theInnerText}>
                        <Paragraph
                          color="#fff"
                          size="500"
                          bold="400"
                          margin="1rem 0"
                          center
                        >
                          Polyurethane Foam
                        </Paragraph>
                      </div>
                      {/* </div> */}
                    </a>
                  </Grid>

                  <Grid item xs={4} sm={4} md={4}>
                    <a className={classes.theProduct}>
                      <img src={product004} />
                      {/* <div className={classes.theOverlay}> */}
                      <div
                        className={classes.theInnerText}
                        alt="View All Product"
                      >
                        <Paragraph
                          color="#fff"
                          size="500"
                          bold="400"
                          margin="1rem 0"
                          center
                        >
                          View All Products
                        </Paragraph>
                      </div>
                      {/* </div> */}
                    </a>
                  </Grid>
                </Grid>
              </SRLWrapper>
            </div>
          </Section>

          <Section pd="2rem 1rem" bg="#f2f2f2">
            <div className={classes.theDot}>
              <img src={dot001} />
            </div>
            <Container>
              <Paragraph color="#5b5692" size="600" bold="600" margin="1rem 0">
                Industries we served
              </Paragraph>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={4} sm={4} md={2}>
                  <div className={classes.theIcon}>
                    <div className={classes.theIconIMG}>
                      <img src={construction} />
                    </div>
                  </div>
                  <Paragraph center margin="1rem 0">
                    Contruction
                  </Paragraph>
                </Grid>
                <Grid item xs={4} sm={4} md={2}>
                  <div className={classes.theIcon}>
                    <div className={classes.theIconIMG}>
                      <img src={medical} />
                    </div>
                  </div>
                  <Paragraph center margin="1rem 0">
                    Medical
                  </Paragraph>
                </Grid>
                <Grid item xs={4} sm={4} md={2}>
                  <div className={classes.theIcon}>
                    <div className={classes.theIconIMG}>
                      <img src={beg} />
                    </div>
                  </div>
                  <Paragraph center margin="1rem 0">
                    Consumer Goods
                  </Paragraph>
                </Grid>
                <Grid item xs={4} sm={4} md={2}>
                  <div className={classes.theIcon}>
                    <div className={classes.theIconIMG}>
                      <img src={furniture} />
                    </div>
                  </div>
                  <Paragraph center margin="1rem 0">
                    Furniture
                  </Paragraph>
                </Grid>
                <Grid item xs={4} sm={4} md={2}>
                  <div className={classes.theIcon}>
                    <div className={classes.theIconIMG}>
                      <img src={electronic} />
                    </div>
                  </div>
                  <Paragraph center margin="1rem 0">
                    Electronic
                  </Paragraph>
                </Grid>
                <Grid item xs={4} sm={4} md={2}>
                  <div className={classes.theIcon}>
                    <div className={classes.theIconIMG}>
                      <img src={marine} />
                    </div>
                  </div>
                  <Paragraph center margin="1rem 0">
                    Marine
                  </Paragraph>
                </Grid>
              </Grid>
            </Container>
            <br />
          </Section>
          <Section pd="2rem 1rem">
            <Container>
              <div className={classes.theDot_}>
                <img src={dot002} />
              </div>
              <Paragraph size="600" bold="600" margin="0">
                We'd love to hear from you
              </Paragraph>
              <div className={classes.theSection} id={"contact"}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className={classes.theContactIMG}>
                      <div className={classes.theRectangle}>
                        <img src={purplerectangle} />
                      </div>
                      <img src={default002} />
                      {/* googlemap */}
                      {/* <iframe
                        className="c-map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.0582457259497!2d103.28514621533009!3d1.7019334608157395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31d08eb6774cbecf%3A0x42295d6894cd40ee!2sTag%20Alliance%20(M)%20Sdn.%20Bhd.!5e0!3m2!1sen!2smy!4v1635311029935!5m2!1sen!2smy"
                        allowfullscreen=""
                        loading="lazy"
                      ></iframe> */}
                      <div className={classes.theRectangleRight}>
                        <img src={bluerectangle} />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className={classes.theContactForm}>
                      <FormInput label="Email" bg="#edeaea" />
                      <FormInput label="Name" bg="#edeaea" />
                      <FormInput label="Company" bg="#edeaea" />
                      <FormInput
                        label="Message"
                        bg="#edeaea"
                        multiple
                        row={4}
                      />
                      <br />
                      <Button label="Get In Touch" bg="#5b5692" color="#fff" />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </Section>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
}
