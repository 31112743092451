import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const config = {
  root: {},
};

export const CardContainer = styled.div`
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 8%);
  padding: 2rem;
`;

export default makeStyles(config);
