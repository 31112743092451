import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { md } from "../../styles/responsive";
import { FormControl, TextField } from "@material-ui/core";

const config = {

    theSection: {
        position: "relative",
        margin: "2rem 0"
    },

    theIMG: {
        position: "relative",
        width: "80%",
        margin: "0 auto",

        "& img": {
            width: "100%"
        }
    },

    theProdDetail: {

    },

    theBtn: {
        width: "60%",

        ...md("sm", {
            width: "50%",
        }),

        ...md("xmd", {
            width: "34%",
        }),
    },

    theProduct: {
        position: "relative",
        width: "100%",
        display: "flex",
        "& img": {
            width: "100%"
        }
    },

    theService: {
        position: "relative",
        width: "100%",
        margin: "0 auto",
        display: "block",

        ...md("sm", {
            width: "90%",
        }),
        "& img": {
            width: "100%"
        }
    },

    theSubmitBtn: {
        width: "100%",
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end",
    },

};

export const ModalEnquiry = styled.div`
  position: absolute;
  background-color: #fff;
  top: 50%;
  width: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5px #ccc;
  padding: 1rem;
  @media screen and (min-width: 768px) {
    width: 70%;
  }
  @media screen and (min-width: 992px) {
    width: 60%;
  }
  @media screen and (min-width: 1440px) {
    width: 40%;
  }
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 1rem 1rem 0 0;
`;

export const ModalBody = styled.div`
  position: relative;
  padding: 1rem;
`;

export const FlexIcon = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  cursor: pointer;
`;

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #eaeaea;
  background-clip: padding-box;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export default makeStyles(config);
