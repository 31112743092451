import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { md } from "../../styles/responsive";


const config = {

	theBanner: {
		position: "relative",
		width: "100%",

		"& img": {
			width: "100%"
		}
	},

	theBigTitle: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: `translate(-50%,-50%)`
	},

	theSection: {
		position: "relative",
		margin: "2rem 0"
	},

	theIMG: {
		position: "relative",
		width: "100%",
		margin: "0 auto",

		...md("md", {
			width: "80%",
		}),

		"& img": {
			width: "100%"
		}
	},

	theDetail: {
		padding: "0 2rem",

		...md("md", {
			padding: "0 4rem",
		}),
	},

	theBtn: {
		width: "60%",

		...md("sm", {
			width: "50%",
		}),

		...md("xmd", {
			width: "34%",
		}),
	},

	theProduct: {
		position: "relative",
		width: "100%",
		display: "flex",
		"& img": {
			width: "100%"
		}
	},

	theProd: {
		position: "relative",
		width: "100%",
		margin: "0 auto",
		display: "block",

		...md("sm", {
			width: "100%",
		}),
		"& img": {
			width: "100%"
		}
	},


	theCount: {
		position: "relative",
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		padding: "2rem 0"

	},

	theTextBox: {
		position: "relative",
		width: "25%",
		borderRight: "1px solid #eaeaea",

		"&:last-child": {
			borderRight: "unset"
		}
	},

	theIconList: {
		position: "relative",
		// alignItems: "center",
		// justifyContent: "space-between",
		// display: "flex",
		padding: "2rem",
		background: "#eaeaea"
	},

	theIcon: {
		position: "relative",
		width: "50%",
		margin: "0 auto",
		"& img": {
			width: "100%",
		}
	},

	theBackground: {
		position: "relative",
		width: "100%",
		"& img": {
			width: "100%",
		}
	},

	theTitle: {
		position: "absolute",
		top: "50%",
		left: "45%",
		transform: `translate(-50%,-50%)`
	},

	theTitle_: {
		position: "absolute",
		top: "50%",
		left: "60%",
		transform: `translate(-50%,-50%)`
	},

	thePadd: {
		alignItems: "center",
		display: "flex"
	},

	theMobile: {
		display: "flex",
		flexDirection: "column-reverse",
		
		...md("md", {
			flexDirection: "row",
		}),
	}

};

export default makeStyles(config);
