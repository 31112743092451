import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import "./App.css";
import CartContextProvider from "./context/CartContext";
import SimpleReactLightbox from 'simple-react-lightbox'
import "aos/dist/aos.css";

function App() {
  return (
    <SimpleReactLightbox>
      <CartContextProvider>
        <BrowserRouter>
          <Routes></Routes>
        </BrowserRouter>
      </CartContextProvider>
    </SimpleReactLightbox>

  );
}

export default App;
