import styled from "styled-components";
import Loader from "react-loader-spinner";

export const LoaderContainer = styled.div`
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  position: absolute;
`;

export const LoaderStyle = styled(Loader)`
  
`;
