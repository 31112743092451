import React from "react";
import FormLabel from "../Typography/FormLabel";
import { FormGroup, TextInput } from "./styles";

const FormInput = ({
  children,
  labelcolor,
  bold,
  label,
  margin,
  bg,
  color,
  mb,
  ...props
}) => {
  return (
    <FormGroup mb={mb}>
      <FormLabel labelcolor={color} bold={bold} margin={margin}>
        {label}
      </FormLabel>
      <TextInput  bg={bg} color={color} {...props}>
        {children}
      </TextInput>
    </FormGroup>
  );
};

export default FormInput;
