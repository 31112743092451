import React from "react";
import { Container, Grid } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { Button } from "../../components/Button/Button";
import Slider from "react-slick";
import Card from "../../components/Card/Card";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import FormInput from "../../components/FormInput/FormInput";
import Loader from "../../components/Loader/Loader";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Footer from "../../components/Footer/Footer";

import default001 from "../../assets/images/default001.png";

import iso from "../../assets/images/iso.png";
import rectangle001 from "../../assets/images/rectangle001.png";
import rectangle002 from "../../assets/images/rectangle002.png";
import about001 from "../../assets/images/about001.jpg";
import about002 from "../../assets/images/about002.jpg";
import about003 from "../../assets/images/about003.jpg";
import about004 from "../../assets/images/about004.jpg";
import banner_ from "../../assets/images/banner_.png";

import useStyles from "./styles";
import {

} from "../../api/API";

export default function About() {
	const classes = useStyles();

	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 5,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 8000,
		responsive: [
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					initialSlide: 3,
					arrows: false,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					initialSlide: 1,
					arrows: false,
				},
			},
		],
	};

	let { Product } = useLocation();
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [Product]);

	// API

	const [isBusy, setIsBusy] = React.useState(true);

	React.useEffect(() => {
		setIsBusy(true);
	}, []);

	return (
		<React.Fragment>
			<Navbar />
			{isBusy != true ? (
				<>
					<Loader
						type="TailSpin"
						color="#ff8001"
						height={80}
						timeout={6000}
						width={80}
					/>
				</>
			) : (
				<>
					<div className={classes.theBanner}>
						<img src={banner_} />
						<div className={classes.theBigTitle}>
							<Paragraph size="700" color="#fff" bold="700" margin="1rem 0" center>About Us</Paragraph>
						</div>
					</div>
					<Container>
						<Section pd="2rem 1rem">
							<div className={classes.theSection}>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={6} md={6}>
										<div className={classes.theProdDetail}>
											<Paragraph size="700" bold="700" margin="1rem 0">About Us</Paragraph>
											<Paragraph size="500" margin="1rem 0">Started in 2004, Tag Alliance has been in the professional packaging industry for at least 3 decades now. With our vast knowledge and experience, we specialize mainly in packaging technology & research, design & testing and supplies of a comprehensive range of protective packaging materials.</Paragraph>
											<Paragraph size="500" margin="1rem 0">We are committed to providing unparalleled quality products coupled with responsive and professional communication. Our goal is to be a company that helps businesses reduce costs and streamline efficiency through our “Total Packaging Solutions”. We will always look to increase our value to our customers through continuous improvement efforts and technological advancements. Our organization is dynamic and ever-evolving to meet the needs and wants of the market and our customers as competencies and resources allow.</Paragraph>
											<Paragraph size="500" margin="1rem 0">Tag Alliance is proud to announce that we have achieved the highest level of product packaging certification. This certification provides proof that we are committed to providing quality packaging products by implementing measurable high quality and safe packaging for our customer’s products.</Paragraph>
										</div>
									</Grid>
									<Grid item xs={12} sm={6} md={6}>
										<div className={classes.theIMG}>
											<img src={default001} />
										</div>
									</Grid>
								</Grid>
							</div>
							<div className={classes.theCount}>
								<div className={classes.theTextBox}>
									<Paragraph size="700" margin="1rem 0" color="#5b5692" bold="600" center>2004 </Paragraph>
									<Paragraph size="300" margin="1rem 0" center>Enstablished </Paragraph>
								</div>
								<div className={classes.theTextBox}>
									<Paragraph size="700" margin="1rem 0" color="#5b5692" bold="600" center>100+ </Paragraph>
									<Paragraph size="300" margin="1rem 0" center>Employees </Paragraph>
								</div>
								<div className={classes.theTextBox}>
									<Paragraph size="700" margin="1rem 0" color="#5b5692" bold="600" center>1000+ </Paragraph>
									<Paragraph size="300" margin="1rem 0" center>Projects </Paragraph>
								</div>
								<div className={classes.theTextBox}>
									<Paragraph size="700" margin="1rem 0" color="#5b5692" bold="600" center>1000+ </Paragraph>
									<Paragraph size="300" margin="1rem 0" center>Clients </Paragraph>
								</div>
							</div>
						</Section>
					</Container>

					<Section pd="0">
						<div className={classes.theIconList}>
							<Slider {...settings}>
								<div>
									<div className={classes.theIcon}>
										<img src={iso} />
									</div>
								</div>
								<div>
									<div className={classes.theIcon}>
										<img src={iso} />
									</div>
								</div>
								<div>
									<div className={classes.theIcon}>
										<img src={iso} />
									</div>
								</div>
								<div>
									<div className={classes.theIcon}>
										<img src={iso} />
									</div>
								</div>
								<div>
									<div className={classes.theIcon}>
										<img src={iso} />
									</div>
								</div>
								<div>
									<div className={classes.theIcon}>
										<img src={iso} />
									</div>
								</div>
							</Slider>
						</div>
					</Section>

					<Section pd="0">
						{/* <div className={classes.theSection}> */}
						<Grid container spacing={0}>
							<Grid item xs={12} sm={6} md={6}>
								<div className={classes.theBackground}>
									<img src={rectangle001} />
									<div className={classes.theTitle}>
										<Paragraph color="#fff" size="600" bold="700">Our Mission</Paragraph>
									</div>
								</div>
							</Grid>
							<Grid item xs={12} sm={6} md={6} className={classes.thePadd}>
								<div className={classes.theDetail}>
									<Paragraph size="500" margin="1rem 0">Tag Alliance’s mission is to provide our clients with the most cost-effective, efficient and complete packaging solutions.</Paragraph>
								</div>
							</Grid>
						</Grid>
						{/* </div> */}
						{/* <div className={classes.theSection}> */}
						<Grid container spacing={0} className={classes.theMobile}>
							<Grid item xs={12} sm={6} md={6} className={classes.thePadd}>
								<div className={classes.theDetail}>
									<Paragraph size="500" margin="1rem 0">Our vision is to be one of the best flexible packaging companies in the industry – by delivering packaging excellence defined by topnotch quality, unmatched customer service, constant improvement, and constant innovation.</Paragraph>
								</div>
							</Grid>
							<Grid item xs={12} sm={6} md={6}>
								<div className={classes.theBackground}>
									<img src={rectangle002} />
									<div className={classes.theTitle_}>
										<Paragraph color="#fff" size="600" bold="700">Our Vision</Paragraph>
									</div>
								</div>

							</Grid>
						</Grid>
						{/* </div> */}
					</Section>

					<Container>
						<Section pd="2rem 1rem">
							<div className={classes.theSection}>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={6} md={6}>
										<div className={classes.theProdDetail}>
											<Paragraph size="700" bold="700" margin="1rem 0">A Glance At Our Operations</Paragraph>
											<Paragraph size="500" margin="1rem 0">We’re driven by our purpose: to improve the environmental performance of our packaging. </Paragraph>
											<Paragraph size="500" margin="1rem 0">We need packaging to keep your product safe, protect it during transportation, extend shelf life and reduce waste. However, these requirements should not come at the expense of the planet, which is why we are innovating solutions with stronger sustainability credentials. </Paragraph>
											<Paragraph size="500" margin="1rem 0">On the pages that follow, you'll learn more about the journey of our remarkable company, its performance, its brands and its innovations, and you’ll meet our leadership team. You can also stay in touch with developments at our factories by subscribing to us on social media or contacting us through the website.</Paragraph>
										</div>
									</Grid>
									<Grid item xs={12} sm={6} md={6}>
										<div className={classes.theIMG}>
											<img src={about001} />
										</div>
									</Grid>
								</Grid>
							</div>
						</Section>
					</Container>

					<Container>
						<Section pd="2rem 1rem">
							<div className={classes.theSection}>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={4} md={4}>
										<div className={classes.theProd}>
											<img src={about004} />
										</div>
									</Grid>
									<Grid item xs={12} sm={4} md={4}>
										<div className={classes.theProd}>
											<img src={about002} />
										</div>
									</Grid>
									<Grid item xs={12} sm={4} md={4}>
										<div className={classes.theProd}>
											<img src={about003} />
										</div>
									</Grid>
								</Grid>
							</div>

						</Section>
					</Container>
					<Footer />
				</>
			)}
		</React.Fragment>
	);
}
