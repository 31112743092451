import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Close, Search } from "@material-ui/icons";

import {
  NavbarSidebarList,
  NavbarSidebarListLogo,
  CloseButtonBox,
  CloseIcon,
  SearchContainer,
  Box,
  SearchField,
  SearchButtonMoblie,
  SearchIcon,
} from "./styles";
import { get_category } from "../../api/API";
import logo from "../../assets/images/logo.png"

const useStyle = makeStyles((theme) => ({
  sidenavbar: {
    width: 300,
  },
}));

const SidebarMenu = ({ open, onClose, onOpen, handleDrawerToggle }) => {
  const classes = useStyle();
  const sideDrawerList = (anchor) => (
    <div
      className={classes.sidenavbar}
      role="presentation"
      onClick={() => handleDrawerToggle(anchor, false)}
      onKeyDown={() => handleDrawerToggle(anchor, false)}
    ></div>
  );

  // SEARCH PRODUCT

  return (
    <Drawer anchor="left" open={open} onOpen={onOpen} onClose={onClose}>
      {sideDrawerList("left")}
      <List component="nav">
        <NavbarSidebarList>
          <NavbarSidebarListLogo>
            <Link to="/"><img src={logo} /></Link>
          </NavbarSidebarListLogo>
          <CloseButtonBox>
            <Link onClick={handleDrawerToggle}>
              <CloseIcon />
            </Link>
          </CloseButtonBox>

          <br/>
          <br/>
          <ListItem>
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
                width: "100%",
              }}
              to="/"
            >
              <ListItemText primary="Home" />
            </Link>
          </ListItem>
          <ListItem>
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
                width: "100%",
              }}
              to="/about"
            >
              <ListItemText primary="About" />
            </Link>
          </ListItem>
          <ListItem>
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
                width: "100%",
              }}
              to="/product"
            >
              <ListItemText primary="Product" />
            </Link>
          </ListItem>
          <ListItem>
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
                width: "100%",
              }}
              to="/contact"
            >
              <ListItemText primary="Contact" />
            </Link>
          </ListItem>
        </NavbarSidebarList>
      </List>
    </Drawer>
  );
};

export default SidebarMenu;
