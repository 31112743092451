import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Close, Search } from "@material-ui/icons";

export const FormGroup = styled.div`
  margin-bottom: ${(props) => (props.mb ? props.mb : "1rem")};
`;

export const TextInput = styled(TextField)`
  display: block !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem;
  line-height: 1.5;
  color: ${(props) => props.textcolor};
  background: ${(props) => props.bg};
  background-clip: padding-box;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;
